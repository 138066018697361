@import '~antd/dist/antd.css';

.App {
  text-align: center;
}
.login-form {
  margin: 0 auto;
  max-width: 500px;
}

.check-guest-status__intro {
  text-align: center;
}
.check-guest-status__icon {
  display: block;
  margin: 0 auto;
  max-width: 300px;
  padding-bottom: 50px;
}
.check-guest-status__scanner-wrapper {
  margin: 0 auto;
  max-width: 500px;
  padding-bottom: 30px;
}

.check-guest-status-result__card {
  box-shadow: 0px 0px 170px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}
.check-guest-status-result__photo-wrapper {
  text-align: center;
}
.check-guest-status-result__photo-wrapper img {
  border-radius: 50%;
  width: 300px;
  height: 300px;
}
.check-guest-status-result__relation {
  font-style: italic;
}
.check-guest-status-result__actions {
  display: flex;
  justify-content: space-between;
}